<template>
  <NuxtLink
    :to="`${linkPrefix}/models/${actor.cachedSlug}`"
    class="flex flex-col"
  >
    <div class="relative">
      <NuxtImg :src="actorThumbUrl" loading="lazy" class="w-full" />
      <div
        class="absolute bottom-2 right-2 bg-black flex flex-col items-end py-1 px-2"
      >
        <span class="uppercase text-xs font-thin tracking-widest text-white">
          Rank
        </span>
        <span class="text-lg text-white">{{ actor.rank }}</span>
      </div>
    </div>

    <div class="flex flex-col gap-1 p-1 leading-none mb-1">
      <NuxtLink
        :to="`${linkPrefix}/models/${actor.cachedSlug}`"
        class="text-white leading-tight text-xl"
      >
        <strong>{{ actor.name }}</strong>
      </NuxtLink>
      <span>{{ actor.videoCount }} Movies</span>
    </div>
  </NuxtLink>
</template>

<script>
  export default {
    name: "ActorCard",
  }
</script>

<script setup>
  const props = defineProps({
    actor: {
      type: Object,
      required: true,
    },
  })

  const { inMembersArea } = useMembersArea()
  const linkPrefix = inMembersArea.value ? "/members" : ""

  const actorThumbUrl = computed(() => {
    return (
      props.actor.thumbUrl ||
      "https://cdn-images.r1.cdn.pornpros.com/content/placeholders/model_portrait_480x850.jpg?imgw=480&imgh=850"
    )
  })
</script>
